import { Component, Input } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

@Component({
  selector: 'apex-logueo-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode >
      <nb-layout-header fixed>
        <apex-logueo-header></apex-logueo-header>
      </nb-layout-header>

      <nb-sidebar>
        <div class="left-column">
          <div class="menu-container">
              <a *ngFor="let item of menus" class="menu-item" [nbTooltip]="item.queryParams.tooltip" [routerLink]="[item.link]" routerLinkActive="selected" >
                <img class="menu-img" [src]="item.icon">
              </a>
          </div>
        </div>
      </nb-sidebar>


      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  @Input() menus!: NbMenuItem[]
}
