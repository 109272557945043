import { Component, Input } from '@angular/core';
import { PresenceService } from '@services/presence/presence.service';

@Component({
  selector: 'apex-logueo-footer',
  templateUrl:'./footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  @Input() showPagination = false;
  @Input() showIncidentRef = false;

  rowsPerPage = [5, 10, 20, 50];
  constructor(public presenceService:PresenceService){}
  
}
