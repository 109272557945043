import { HttpClient, HttpParams } from '@angular/common/http';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'apps/dashboard/src/environments/environment';
import { firstValueFrom } from 'rxjs';


export abstract class BaseService<T, TFilter> {
  constructor(protected httpClient: HttpClient, protected resource: string) {
  }


  get baseUrl(): string {
    return `${environment.apiUrl}`
  }

  get apiUrl(): string {
    return `${environment.apiUrl}/api`
  }

  get resourceUrl(): string {
    return `${environment.apiUrl}/api/${this.resource}`
  }

  list(filter: TFilter): Promise<T[]> {
    return firstValueFrom(this.httpClient.get<T[]>(`${this.resourceUrl}`, { params: filter as any }))
  }

  csv(filter) {
    return this.httpClient.get(`${this.apiUrl}/Event/AgentEvents/csv`, { params: filter as any, responseType: 'blob' })
   }

/* 
  csv(filter) {
   return this.httpClient.get(this.resourceUrl+'/SupervisorAgentPresences/csv', { params: filter as any, responseType: 'text' })
  } */

}
