<div class="header-container">
  <div class="logo-container">
    <img src="assets/LogoApex.svg">
  </div>
</div>

<div class="header-container">
  <nb-actions size="medium">

    <nb-action>
      <button outline nbButton [nbContextMenu]="languages" nbContextMenuTag="language">
        <nb-icon *ngIf="languagesSelected === 'es'" icon="ar" pack="apex"></nb-icon>
        <nb-icon *ngIf="languagesSelected === 'br'" icon="br" pack="apex"></nb-icon>
      </button>
    </nb-action>
    <!-- <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user nbContextMenuTag="logout" *ngIf="employeeService.$employee | async as employee" [nbContextMenu]="userMenu" [onlyPicture]="true"
      [name]="employee?.nombre + ' ' + employee?.apellido">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
