import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeService } from '@services/employee/employee.service';
import { Router } from '@angular/router';

@Component({
  selector: 'apex-logueo-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly = false;
  user: any;

  languages: NbMenuItem[] = [{ title: 'Español', icon: { pack: 'apex', icon: 'ar', options: { width: '12px' } }, data: 'es' },
  { title: 'Portugues', icon: { pack: 'apex', icon: 'br', options: { width: '12px' } }, data: 'br' }]
  languagesSelected = 'es';

  currentTheme = 'default';
  userMenu = [{ title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private translateService: TranslateService,
    public employeeService: EmployeeService,
    private router: Router) {
  }
  ngOnInit(): void {
    this.menuService.onItemClick().subscribe(async value => {
      if (value.tag === 'language') {
        this.languagesSelected = value.item.data
        this.translateService.use(value.item.data);
      } else if (value.tag === 'logout') {
        this.employeeService.removeEmployeeLocalStorage();
        location.reload()
      }

    })
  }


  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
