<div
  class="footer"
  style="position: relative; height: auto; width: 100%; background-color: #ffff"
  *ngIf="
    presenceService.notFoundSearch === undefined ||
    !presenceService.notFoundSearch
  "
>
  <div class="left">
    <ng-container *ngIf="showIncidentRef">
      <div>
        <nb-icon pack="apex" icon="arrive-before"></nb-icon
        ><span>Llegó Antes</span>
      </div>
      <div>
        <nb-icon pack="apex" icon="arrive-after"></nb-icon
        ><span>Llegó Tarde</span>
      </div>
      <div>
        <nb-icon pack="apex" icon="withdrawall-before"></nb-icon
        ><span>Retiro Antes</span>
      </div>
      <div>
        <nb-icon pack="apex" icon="withdrawall"></nb-icon
        ><span>Retiro Después</span>
      </div>
    </ng-container>
  </div>
  <div class="right">
    <ng-container *ngIf="showPagination">
      <span class="px-2">Líneas por página</span>
      <nb-select
        size="tiny"
        [(selected)]="presenceService.rowsPerPageSelected"
        (selectedChange)="presenceService.changeRowsPerPage()"
      >
        <nb-option
          *ngFor="let item of rowsPerPage; index as i"
          [value]="item"
          >{{ item }}</nb-option
        >
      </nb-select>
      <span class="info">{{ presenceService.infoPagination }}</span>
      <a
        [ngClass]="{ disable: presenceService.page === 0 }"
        (click)="presenceService.prevPage()"
      >
        <nb-icon pack="apex" icon="arrow-left"></nb-icon>
      </a>
      <a
        [ngClass]="{
          disable: presenceService.page === presenceService.pages - 1
        }"
        (click)="presenceService.nextPage()"
      >
        <nb-icon pack="apex" icon="arrow-right"></nb-icon>
      </a>
    </ng-container>
  </div>
</div>
