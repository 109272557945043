import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PresenceFilter } from '@api-services/filters/presence-filter';
import { Presence } from '@api-services/models/presence';
import { BaseService } from '@api-services/services/base.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PresenceService extends BaseService<Presence, PresenceFilter> {

  constructor(httpClient: HttpClient) { super(httpClient, 'PresenceHeader/Presences') }

  getPresenceByLegajo(legajo:string, account:string , puesto: string, filter:PresenceFilter):Promise<Presence[]>{
    const h = { legajo , account , puesto};
    const headers:HttpHeaders = new HttpHeaders(h);
    //return firstValueFrom(this.httpClient.get<any[]>('assets/presence-mock.json'));

    return firstValueFrom(this.httpClient.get<Presence[]>(`${this.apiUrl}/PresenceHeader/Headers`,{ headers:headers , params: filter as any }))
  }

  listPresencesHeader(filter: PresenceFilter): Promise<Presence[]> {
    return firstValueFrom(this.httpClient.get<Presence[]>(`${this.apiUrl}/PresenceHeader/Headers`, { params: filter as any }))
  }

  csv(filter) {
    return this.httpClient.get(`${this.apiUrl}/Event/SupervisorAgentsPresences/csv`, { params: filter as any, responseType: 'blob' })
   }

  // list(filter: PresenceFilter): Promise<Presence[]> {
  //   return firstValueFrom(this.httpClient.get<any[]>('assets/presence-mock.json'));
  // }

  

}
