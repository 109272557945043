import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { EmployeeService } from '@services/employee/employee.service';

export const authenticatedGuard: CanActivateFn = async (route, state) => {
  const employee = inject(EmployeeService).getEmployeeByLocalStorage()
  if (employee) {
    inject(Router).navigate(['/'])
    return false;
  }


  return true;
};
