import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmployeeService } from '@services/employee/employee.service';

@Injectable()
export class ApiApexInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const employee = inject(EmployeeService).getEmployeeByLocalStorage()
    
    if (employee) {

      const legajo = request.headers.has('legajo') ? request.headers.get('legajo') : employee.legajo;
      const puesto = request.headers.has('puesto') ? request.headers.get('puesto') : employee.idPuesto;

      const newRequest = request.clone({
        headers: request.headers
          .set('legajo', legajo)
          .set('account', employee.idCuenta)
          .set('puesto', puesto)
      })
      return next.handle(newRequest);
    }
    return next.handle(request);
  }
}
