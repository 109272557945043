import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from '@services/employee/employee.service';

@Component({
  selector: 'apex-logueo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  legajo: string;
  error;
  isLoading = false;
  constructor(private employeeService: EmployeeService,
    private router: Router) { }

  async login() {
    if (this.legajo) {
      this.error = undefined;
      try {
        this.isLoading = true
        const result = await this.employeeService.getMe(this.legajo);
        if (result === null  || result === undefined ) {
          this.error = "El legajo es inválido";
        } /*  else if(result && result.puesto !== 'Supervisor') {
          this.error = 'El legajo no corresponde a un Supervisor'
        } */ else{ 

          this.employeeService.saveEmployeeLocalStorage(result)
          this.router.navigate(['/pages'])
        }
      }
      catch{
        this.error = "El legajo es inválido";
      }     
      finally {
        this.isLoading = false;
      }

    } else {
      this.error = "Debe ingresar un legajo";
    }

  }
}
