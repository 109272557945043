import { Injectable } from '@angular/core';
import { Employee } from '@api-services/models/employee';
import { BehaviorSubject } from 'rxjs';
import { EmployeeService as EmployeeApiService } from '@api-services/services/employee/employee.service';
import { Dotation } from '@api-services/models/dotation';
import { Presence } from '@api-services/models/presence';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  $employee: BehaviorSubject<Employee> = new BehaviorSubject<Employee>(undefined);
  $dotation: BehaviorSubject<Dotation[]> = new BehaviorSubject<Dotation[]>([])

  public currentAccountDate;
  
  constructor(private employeeApiService: EmployeeApiService) { }

  async getMe(legajo: string): Promise<Employee> {
    
      const employee = await this.employeeApiService.me(legajo)
      if (employee) {
        this.$employee.next(employee);
      }   

    return this.$employee.value
  }

  async getEmployeeInfo(agentNumber){
    const employee = await this.employeeApiService.getEmployeeInformation(agentNumber);
    return employee
  }

  async getDotation() {
    const result = await this.employeeApiService.getDotation()
    this.$dotation.next(result);
  }

  saveEmployeeLocalStorage(employee: Employee) {
    localStorage.setItem('employee', JSON.stringify(employee));
  }

  getEmployeeByLocalStorage(): Employee {
    const value = localStorage.getItem('employee');
    if (value) {
      return JSON.parse(value) as Employee
    }

    return null;
  }

  removeEmployeeLocalStorage() {
    localStorage.removeItem('employee')
  }
}
