import { Injectable } from '@angular/core';
import { BaseFilter } from '@api-services/filters/base-filter';
import { Employee } from '@api-services/models/employee';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Dotation } from '@api-services/models/dotation';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends BaseService<Employee, BaseFilter>{

  constructor(protected httpClient: HttpClient) { super(httpClient, 'Employee') }

  me(legajo: string): any {
    return firstValueFrom(this.httpClient.get<Employee>(`${this.resourceUrl}/me`, { headers: { legajo } }))   
  }

  getDotation() {
    return firstValueFrom(this.httpClient.get<Dotation[]>(`${this.resourceUrl}/me/dotation`))
  }

  getEmployeeInformation(legajo:string):Promise<any> {
    return firstValueFrom(this.httpClient.get<Employee>(`${this.resourceUrl}/search?legajo=${legajo}`)) 
  }
}
