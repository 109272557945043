<nb-layout windowMode>
  <nb-layout-column>
    <div class="d-flex justify-content-center">
      <nb-card>
        <nb-card-header>
          <p>Ingrese legajo</p>
        </nb-card-header>
        <nb-card-body>
          <div>
            <input nbInput placeholder="Legajo" (keyup.enter)="login()" [(ngModel)]="legajo" name="legajo">
            <p class="error-login" *ngIf="error">{{error}}</p>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="d-flex justify-content-end">
            <button nbButton  (click)="login()" [disabled]="isLoading">Ingresar</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </nb-layout-column>
</nb-layout>
